import Pristine from 'pristinejs';
import '@lottiefiles/lottie-player';

const config = {
    // class of the parent element where the error/success class is added
    classTo: 'form-group',
    errorClass: 'has-error',
    successClass: 'has-success',
    // class of the parent element where error text element is appended
    errorTextParent: 'form-group',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass:
        'text-help text-jungleBlack relative max-h-0 mt-3 text-sm px-3 py-1 rounded w-fit transition-all ease-in-out',
};

export default (
    csrfTokenName,
    csrfTokenValue,
    makeReservationsAction,
    makeReservationsSuccessUrl,
    phpTimestamp
) => ({
    csrfTokenName,
    csrfTokenValue,
    makeReservationsAction,
    makeReservationsSuccessUrl,
    phpTimestamp,
    init() {
        // set timeout for booking page
        // 2 mins

        // create the pristine instance
        this.pristine = new Pristine(this.$refs.contactDataForm, config, false);

        this.$watch('reservationData', (newValue) => {
            if (newValue.activities.length > 0) {
                this.steps[0].completed = true;

                let dateAndRoundSelected = true;
                for (let activity of newValue.activities) {
                    if (!activity.timestamp || !activity.round) {
                        dateAndRoundSelected = false;
                    }
                }
                if (dateAndRoundSelected) {
                    this.steps[2].completed = true;
                } else {
                    this.steps[2].completed = false;
                }
            } else {
                this.resetSteps();
            }
        });

        this.$watch('currentStep', (newValue) => {
            // when changing screen, always scroll to top first
            window.scrollTo(0, 0);
            this.scrollToSelectedTimestamp();
        });

        // for dev purposes only
        // ! REMOVE AFTER TESTING
        // this.reservationData.activities.push({
        //     activityId: 29,
        //     activityType: 'fairyWorld',
        //     parkId: 21,
        //     parkName: 'Albavilla',
        //     label: 'Fairy World',
        //     participants: 1,
        //     timestamp: 1708121420.6206896,
        //     round: '1100',
        // });
    },
    datesLoading: false,
    selectionTimestamp: null,
    dodgyEmail: false,
    reason: '',
    reservationData: {
        privacy: 0,
        park: null,
        activities: [],
        contact: {
            fullName: null,
            email: null,
            phone: null,
        },
    },

    loading: false,

    error: null,

    currentStep: 0,

    steps: [
        {
            label: 'Attività',
            completed: false,
        },
        {
            label: 'Partecipanti',
            completed: false,
        },
        {
            label: 'Data',
            completed: false,
        },
        {
            label: 'Informazioni',
            completed: false,
        },
    ],

    scrollToSelectedTimestamp() {
        if (this.reservationData.activities.length > 0) {
            const timestamp = this.reservationData.activities[0].timestamp;
            if (timestamp) {
                const roundEl = document.getElementById('round-' + timestamp);
                // found selected element, and we are on the date step
                if (roundEl && this.currentStep == 2) {
                    window.setTimeout(() => {
                        window.scrollTo(0, roundEl.offsetTop - 200);
                    }, 500);
                }
            }
        }
    },

    getProgressPercentage() {
        if (this.currentStep == 0) {
            if (this.steps[0].completed) {
                return 33;
            } else {
                return 17;
            }
        }
        if (this.currentStep == 1) {
            if (this.steps[1].completed) {
                return 50;
            } else {
                return 33;
            }
        }
        if (this.currentStep == 2) {
            if (this.steps[2].completed) {
                return 100;
            } else {
                return 83;
            }
        }
        if (this.currentStep == 3) {
            return 100;
        }
    },

    isStepCompleted(index) {
        return this.steps[index].completed;
    },

    resetSteps() {
        for (let step of this.steps) {
            step.completed = false;
        }
    },

    setStepCompleted(index) {
        this.steps[index].completed = true;
    },

    isActivitySelected(id) {
        return this.reservationData.activities.find(
            (activity) => activity.activityId == id
        );
    },

    toggleActivity(activityId, activityType, parkId, parkName, label) {
        // check if activity is already selected

        // if force reset, no other activity can be present concurrently. useful for park change

        const activityIndex = this.reservationData.activities.findIndex(
            (activity) => activity.activityId == activityId
        );

        if (activityIndex > -1) {
            this.reservationData.activities.splice(activityIndex, 1);
        } else {
            // check if there are other activities, and if they have the same park id. else, clear the array before pushing

            if (this.reservationData.activities.length > 0) {
                // find other activity and check if it has the same park id? *** supports only max 2 activities
                const otherParkActivityIndex =
                    this.reservationData.activities.findIndex(
                        (activity) => activity.parkId != parkId
                    );

                if (otherParkActivityIndex > -1) {
                    this.reservationData.activities = [];
                }
            }

            this.reservationData.activities.push({
                activityId,
                activityType,
                parkId,
                parkName,
                label,
                participants: 1,
                timestamp: null,
                round: null,
            });

            this.markLastChangeTimestamp();
        }
    },

    formatAsHours(str) {
        if (!str) {
            return;
        }
        str = str.toString();
        const substring = ':';
        const index = -2;
        return str.slice(0, index) + substring + str.slice(index);
    },

    timestampToDate(timestamp) {
        return new Date(timestamp * 1000).toLocaleDateString('it', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            timeZone: 'Europe/Rome',
        });
    },

    markLastChangeTimestamp() {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        this.selectionTimestamp = timestamp;
    },

    getActivityLabel(activityId) {
        const activity = this.reservationData.activities.find(
            (a) => a.activityId == activityId
        );
        if (activity) {
            return activity.label;
        }
    },
    isDateTimeSelected(activityId, timestamp, round) {
        const activity = this.reservationData.activities.find(
            (a) => a.activityId == activityId
        );
        if (activity) {
            return activity.timestamp == timestamp && activity.round == round;
        }
        return false;
    },
    isRoundSizeSufficient(activityId, size) {
        const activity = this.reservationData.activities.find(
            (a) => a.activityId == activityId
        );
        if (activity) {
            return activity.participants <= size;
        }
        return false;
    },
    isRoundInTheFuture(round, day) {
        // if the day's timestamp is greater than now, it means it's for a day in the future. timestamps start at midnight each day
        const today = new Date(this.phpTimestamp * 1000);
        if (day.timestamp > today.getTime() / 1000) {
            return true;
        }
        const date = new Date(this.phpTimestamp * 1000 + 1000 * 60 * 15); // 15 minutes in the future
        const hour = date.getHours();
        let min = date.getMinutes();
        if (min < 10) {
            min = '0' + min;
        }
        const currentTime = '' + hour + min;
        return Number(round.time) > Number(currentTime);
    },
    hasAvailableSlots(day) {
        let hasSlots = false;
        if (day.rounds && day.rounds.length > 0) {
            for (let round of day.rounds) {
                if (
                    round.available > 0 &&
                    this.isRoundInTheFuture(round, day)
                ) {
                    hasSlots = true;
                }
            }
        }
        return hasSlots;
    },
    goToNextStep() {
        // se siamo nella selezione date e non ho selezionato le date, blocca e emetti errore

        if (this.currentStep == 2) {
            let datesSelected = true;
            for (let activity of this.reservationData.activities) {
                if (!activity.timestamp) {
                    datesSelected = false;
                }
            }
            if (datesSelected) {
                this.currentStep += 1;
                this.error = null;
            } else {
                if (this.reservationData.activities.length > 1) {
                    this.error =
                        'Seleziona un orario di ingresso per ciascuna attività';
                } else {
                    this.error =
                        "Seleziona un orario di ingresso per l'attività";
                }
            }

            return;
        }
        if (this.currentStep == 3) {
        }

        // default case
        this.error = null;
        this.currentStep += 1;
    },
    makeReservations() {
        this.error = null;

        const valid = this.pristine.validate(); // returns true or false
        if (!valid) {
            window.setTimeout(() => {
                //this.error = 'Controlla i dati della prenotazione';
                this.loading = false;
            }, 300);
            return;
        }
        this.loading = true;
        // added new data point to measure conversions delta
        this.sendEarlyGtmData();
        let formData = new FormData();
        formData.append(this.csrfTokenName, this.csrfTokenValue);
        formData.append('redirect', this.makeReservationsSuccessUrl);
        for (let activity of this.reservationData.activities) {
            formData.append(
                'activities[' + activity.activityId + '][activityId]',
                activity.activityId
            );
            formData.append(
                'activities[' + activity.activityId + '][type]',
                activity.activityType
            );
            formData.append(
                'activities[' + activity.activityId + '][parkId]',
                activity.parkId
            );
            formData.append(
                'activities[' + activity.activityId + '][label]',
                activity.label
            );
            formData.append(
                'activities[' + activity.activityId + '][participants]',
                activity.participants
            );
            formData.append(
                'activities[' + activity.activityId + '][timestamp]',
                activity.timestamp
            );
            formData.append(
                'activities[' + activity.activityId + '][round]',
                activity.round
            );
            formData.append(
                'activities[' + activity.activityId + '][requiresPayment]',
                false
            );
        }

        formData.append(
            'contact[fullName]',
            this.reservationData.contact.fullName
        );
        formData.append('contact[email]', this.reservationData.contact.email);
        formData.append('contact[phone]', this.reservationData.contact.phone);
        if (this.dodgyEmail) {
            formData.append('dodgyEmail', 1);
            formData.append('reason', this.reason);
            formData.append('subReason', this.subReason);
        }

        fetch(this.makeReservationsAction, {
            method: 'POST',
            body: formData,
            headers: {
                Accept: 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                //console.log(data);
                if (data['ok']) {
                    this.sendGtmData();
                    setTimeout(() => {
                        window.location.href = data['redirect'];
                    }, 500);
                } else {
                    this.error = data['message'];
                    this.loading = false;
                }
            })

            .catch((error) => {
                console.log(error);
                this.loading = false;
            });
    },
    sendGtmData() {
        if (
            typeof dataLayer === 'undefined' ||
            typeof dataLayer.push !== 'function'
        ) {
            return;
        }

        for (let activity of this.reservationData.activities) {
            dataLayer.push({
                activityId: activity.activityId,
                activityType: activity.activityType,
                parkId: activity.parkId,
                parkName: activity.parkName,
                participants: activity.participants,
                timestamp: activity.timestamp,
                round: activity.round,
                conversionValue: 45 * activity.participants,
                event: 'prenotazioni',
            });
        }

        return;
    },
    sendEarlyGtmData() {
        if (
            typeof dataLayer === 'undefined' ||
            typeof dataLayer.push !== 'function'
        ) {
            return;
        }

        for (let activity of this.reservationData.activities) {
            dataLayer.push({
                activityId: activity.activityId,
                activityType: activity.activityType,
                parkId: activity.parkId,
                parkName: activity.parkName,
                participants: activity.participants,
                timestamp: activity.timestamp,
                round: activity.round,
                conversionValue: 45 * activity.participants,
                event: 'click_prenota',
            });
        }

        return;
    },
    limitParticipants() {
        for (let activity of this.reservationData.activities) {
            if (activity.participants > 40) {
                activity.participants = 40;
            }
            if (activity.participants < 1) {
                activity.participants = 1;
            }
        }
    },
});
