import Pristine from 'pristinejs';
import '@lottiefiles/lottie-player';

const config = {
    // class of the parent element where the error/success class is added
    classTo: 'form-group',
    errorClass: 'has-error',
    successClass: 'has-success',
    // class of the parent element where error text element is appended
    errorTextParent: 'form-group',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass:
        'text-help text-white relative max-h-0 mt-3 text-sm px-3 py-1 rounded w-fit transition-all ease-in-out',
};

export default (
    csrfTokenName,
    csrfTokenValue,
    saveEntryAction,
    reservationId,
    makeReservationsAction,
    makeReservationsSuccessUrl,
    reservationSlug,
    participants,
    tickets
) => ({
    csrfTokenName,
    csrfTokenValue,
    makeReservationsAction,
    makeReservationsSuccessUrl,
    reservationSlug,
    participants,
    tickets,
    init() {
        // create the pristine instance
        //this.pristine = new Pristine(this.$refs.contactDataForm, config, false);
        if (this.tickets.length > 0) {
            this.hideTicketInput = false;
        }
    },

    hideTicketInput: true,
    showAddTicket: true,
    getQuantityOfPeopleWhoPay() {
        return participants - this.tickets.length;
    },

    ticketInput: null,
    showTicketError: false,
    paymentGateways: [
        {
            id: 1,
            label: 'Carta di credito',
        },
        {
            id: 2,
            label: 'PayPal',
        },
    ],
    selectedGateway: null,
    addTicket(code) {
        this.showTicketError = false;

        if (this.validateTicket(code)) {
            this.tickets.push(code);
            this.saveTickets();
            this.ticketInput = null;
        } else {
            this.showTicketError = true;
        }
    },

    removeTicketByIndex(index) {
        this.tickets.splice(index, 1);
        this.saveTickets();
    },

    validateTicket(code) {
        if (!code) {
            return false;
        }
        code = code.toUpperCase();
        if (this.tickets.includes(code)) {
            return false;
        }
        const regex = /^(?:JRPX|JUNGLE)2\d(?:EX|ALL)-\d{6}$/gm;
        // examples: JRPX24EX-910672 oppure JUNGLE24EX-148594 oppure JRPX24ALL-624453
        return code.match(regex);
    },

    loading: false,

    error: null,

    currentStep: 0,

    steps: [
        {
            label: 'Biglietti',
            completed: false,
        },
        {
            label: 'Pagamento',
            completed: false,
        },
    ],

    isStepCompleted(index) {
        return this.steps[index].completed;
    },

    resetSteps() {
        for (let step of this.steps) {
            step.completed = false;
        }
    },

    setStepCompleted(index) {
        this.steps[index].completed = true;
    },

    formatAsHours(str) {
        if (!str) {
            return;
        }
        str = str.toString();
        const substring = ':';
        const index = -2;
        return str.slice(0, index) + substring + str.slice(index);
    },

    timestampToDate(timestamp) {
        return new Date(timestamp * 1000).toLocaleDateString('it', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            timeZone: 'Europe/Rome',
        });
    },

    goToNextStep() {
        // default case
        this.error = null;
        this.currentStep += 1;
    },

    sendGtmData() {
        if (
            typeof dataLayer === 'undefined' ||
            typeof dataLayer.push !== 'function'
        ) {
            return;
        }
        return;
    },
    saveTickets() {
        //console.log('saving');
        let formData = new FormData();
        formData.append(csrfTokenName, csrfTokenValue);
        formData.append('reservationId', reservationId);
        formData.append('tickets', JSON.stringify(this.tickets));
        fetch(saveEntryAction, {
            method: 'POST',
            body: formData,
            headers: {
                Accept: 'application/json',
            },
        })
            .then((response) => {
                if (response.ok) {
                    //console.log('saved');
                    const url = new URL(window.location.href);
                    if (!url.searchParams.has('dnt')) {
                        location.assign(document.location.href + '&dnt=1');
                    } else {
                        location.reload();
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                this.result = false;
            });
    },
});
